import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import FurtherReading from "../components/furtherReading"

const SubcriptionBenefit = () => (
  <Layout>
    <SEO
      title="6 benefits of a subscription business model"
      description="You might have noticed that subscriptions are everywhere now. If something can be made into a subscription service then it has probably been done already"
    />
    <h1>6 Benefits of a Subscription Business Model</h1>


    <p>You might have noticed that subscriptions are everywhere now. If something can be made into a subscription service then it has probably been done already. If it hasn’t then you better believe someone is out there trying to figure it out. If you want to see some examples, just type your chosen service or product, add ‘subscription’ to it then do a Google search. If you don’t want to do that then I’ll save some examples for you at the end.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/yZBg8Sd.jpg"
        alt="benefits of a subscription business model"
        description="Mobile phone with post-it note stuck to the lower part of the screen saying sign here"
      />
    </div>
    <p>
      Photo by 
      <a
        href="https://unsplash.com/@kellysikkema"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
       Kelly Sikkema 
      </a>
       on Unsplash
    </p>

    <p>This is far from a coincidence or a mistake, there is a very good reason for it. Let’s take a look at some of the benefits of a subscription model. I will try to come up with some disadvantages as well, as of writing this part I can’t think of any so let’s see what happens.</p>

    <h3>Customer details</h3>
    <p>When you have a customer on subscription, you have to have their contact details too. As a minimum you will have their payment information, name, email probably home address too. In business, the more information you have on your customers, the better decisions you can make but that’s only the start of it.</p>
    <p>With a person’s email address, you can keep them updated on your business and turn them into real fans. If you have multiple products, you can warm them up and convert them on these too. At very little cost.</p>

    <h3>Predictable income</h3>
    <p>One of the best things about the subscription model, if not the best, is that your income becomes predictable. Depending on the type of business and the contract your customers sign, you will have to factor in customers leaving. But, if you are a growing company then each month, you will be able to accurately predict how much money you will see come into your business.</p>
    <p>The benefits that come from having this predictability are that you can better plan within your business. You can hire talent and not have to save up a pot to make sure you can pay them. You can make longer term decisions.</p>

    <h3>Cheaper</h3>
    <p>In business there is something called customer acquisition cost (CAC). It is a metric that is very useful because it lets us know how much we can spend to (obviously) acquire new customers. The other metric that correlates with this one in terms of revenue is lifetime value of a customer LTV along with churn.</p>
    <p>Churn is how many customers you lose in a given time period. Let’s ignore churn for now because you run a great business and your customers stay with you for a long time.</p>
    <p>When comparing a single payment business to a subscription business, the cost to acquire a customer might not be all that different. For this example, let’s say your ROI becomes positive when the customer has been with you for 4 months.</p>

    <h3>Easier for investors</h3>
    <p>If you are thinking of selling your business at some point in the future or getting outside investment then having a subscription model can help a lot. As I’ve mentioned above, your income is predictable which is very attractive. Subscription model businesses sell for a much higher multiple. The likelihood of half of your customers churning is far less than half of you customers not purchasing from you because of extreme weather or even a pandemic.</p>

    <h3>Scalable</h3>
    <p>Subscription based businesses, especially digital ones are massively scalable. There are different types of products but ones where you can build once and all of your customers use various features in the same product are wonderful. It means you can keep a relatively small team and keep expenses relatively low.</p>
    <p>The difference between 200,000 customers and 1,000,000 customers in terms of cost is small. You will have extra storage, if you have built your stack to scale then you will be okay there. Then there’s the amount of staff you have. It might not change all that much.</p>

    <h3>Choices</h3>
    <p>If you have a business that isn’t subscription based and you feel like you are missing out, you can figure something out with enough effort. Think about the customers you already have and why they come to you. Then think about how you can systematise the thing that they want from you. Even if it is a physical product, you can sell the information around how to get the best use from it. Or even a newsletter that brings them entertainment and knowledge around the subject. Leverage your expertise to make something great.</p>


    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default SubcriptionBenefit
